/** @jsx jsx */
import { jsx, Styled, Flex } from "theme-ui"

import { Link } from "gatsby"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
    <Layout page="404" bgColor="inherit">
      <Flex
        sx={{
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/">
          <Styled.h1>Sorry, couldn't find that page.</Styled.h1>
        </Link>
      </Flex>
    </Layout>
  )
}
